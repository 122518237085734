<template>
  <v-container fluid style="padding: 24px !important;">
    <v-layout row wrap>
      <v-flex xs3 sm6 md3>
        <v-img src="../../../public/img/userIcons/001-ninja.png"
               aspect-ratio="1"
               class="grey lighten-2 size rounded"
               style="margin-top: 25px;">
          <template v-slot:placeholder>
            <v-layout fill-height
                      align-center
                      justify-center
                      ma-0>
              <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
            </v-layout>
          </template>
        </v-img>
      </v-flex>
      <v-flex xs9 sm6 md9>
        <v-layout row wrap style="padding: 0 15px;">
          <v-flex xs12>
            <v-text-field label="First Name" v-model="profile.firstName"
                          :error-messages="firstNameErrors"
                          @input="$v.profile.firstName.$touch()"
                          @blur="$v.profile.firstName.$touch()"></v-text-field>
          </v-flex>
          <v-flex xs12>
            <v-text-field label="Last Name" v-model="profile.lastName"
                          :error-messages="lastNameErrors"
                          @input="$v.profile.lastName.$touch()"
                          @blur="$v.profile.lastName.$touch()"></v-text-field>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12>
        <v-text-field label="Email" type="text" v-model="profile.email"
                      :error-messages="emailErrors"
                      @input="$v.profile.email.$touch()"
                      @blur="$v.profile.email.$touch()"></v-text-field>

      </v-flex>
      <v-flex xs12>
          <v-menu v-model="date"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px">
              <template v-slot:activator="{ on }">
                  <v-text-field v-model="profile.dob"
                                label="Date Of Birth"
                                readonly
                                v-on="on"></v-text-field>
              </template>
              <v-date-picker v-model="profile.dob" @input="date = false"></v-date-picker>
          </v-menu>
      </v-flex>
      <v-flex xs12>
        <v-select :items="['Male', 'Female']"
                  label="Gender"
                  v-model="profile.gender"></v-select>
      </v-flex>
      <v-flex xs12 md6 style="padding:5px;">
        <v-btn color="info" rounded block v-on:click.prevent="$router.push({ path: '/dashboard/changePassword' })">
          Change Password
        </v-btn>
      </v-flex>
      <v-flex xs12 md6 style="padding:5px;">
        <v-btn color="success" rounded block v-on:click.prevent="saveProfile()">
          Save
        </v-btn>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
    import { validationMixin } from 'vuelidate'
    import { required, email } from 'vuelidate/lib/validators'

    import { HTTP } from '../../api/httpCommon';

  export default {
    mixins: [validationMixin],
    data() {
      return {
        image: "",
        date: false,
        profile: {
          firstName: '',
          lastName: '',
          email: '',
          dob: new Date().toISOString().substr(0, 10),
          gender: ''
        }
      }
    },
    computed: {
      firstNameErrors() {
        const errors = []
        if (!this.$v.profile.firstName.$dirty) return errors
        !this.$v.profile.firstName.required && errors.push('First Name is required')
        return errors
      },
      lastNameErrors() {
        const errors = []
        if (!this.$v.profile.lastName.$dirty) return errors
        !this.$v.profile.lastName.required && errors.push('Last Name is required')
        return errors
      },
      emailErrors() {
        const errors = []
        if (!this.$v.profile.email.$dirty) return errors
        !this.$v.profile.email.required && errors.push('E-mail is required')
        !this.$v.profile.email.email && errors.push('Must be valid e-mail')
        return errors
      },
    },
    methods: {
      showDefaultProfilePic: function () {
          this.image = "./img/userIcons/001-ninja.png"
        },
        getProfileData: function () {
            this.profile = this.$store.state.User
        },
        saveProfile: function () {
            HTTP.put('Profile',
                this.profile
            )
                .then(response => {

                    //if (response.status === 200) {
                    //    this.$router.push({ path: '/login' })
                    //}

                })
                .catch(e => {
                    console.log(e)
                })
      }
    },
    beforeMount() {
        this.getProfileData()
        this.showDefaultProfilePic()
    },
    validations: {
      profile: {
        firstName: {
          required
        },
        lastName: {
          required
        },
        email: {
          required,
          email
        }
       
      }

    }




  }
</script>


<style>
  .size {
    max-height: 150px;
    max-width: 150px;
  }

  .rounded {
    border-radius: 50%;
  }
</style>
